// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Actions
// ----------------------------------------------------------------------------
export const INCREASE = 'INCREASE'
export const DECREASE = 'DECREASE'
export const UPDATE_MEDIA_STATE = 'UPDATE_MEDIA_STATE'
export const UPDATE_SCREEN_SIZE_STATE = 'UPDATE_SCREEN_SIZE_STATE'
export const UPDATE_HEADER_STATE = 'UPDATE_HEADER_STATE'

export const UPDATE_GRID_OVERLAY_VIEW_STATE = 'UPDATE_GRID_OVERLAY_VIEW_STATE'
export const UPDATE_GRID_OVERLAY_STATE = 'UPDATE_GRID_OVERLAY_STATE'

export const UPDATE_DAY_NIGHT_MODE = 'UPDATE_DAY_NIGHT_MODE'

export const UPDATE_COLOR = 'UPDATE_COLOR'
export const UPDATE_COLOR_RANDOM_SWITCH = 'UPDATE_COLOR_RANDOM_SWITCH'

export const UPDATE_THEME = 'UPDATE_THEME'

export const UPDATE_DRAWER = 'UPDATE_DRAWER'
export const UPDATE_DRAWER_TITLE = 'UPDATE_DRAWER_TITLE'
export const UPDATE_DRAWER_ABSTRACT = 'UPDATE_DRAWER_ABSTRACT'

export const UPDATE_TYPE = 'UPDATE_TYPE'

export const UPDATE_SIZE = 'UPDATE_SIZE'

export const HEADER_MENU_STATE = 'HEADER_MENU_STATE'
export const TOGGLE_HEADER_MENU = 'TOGGLE_HEADER_MENU'

export const UPDATE_DRAWER_STATE = 'UPDATE_DRAWER_STATE'

export const UPDATE_CURSOR = 'UPDATE_CURSOR'

export const UPDATE_LOGIN_MODAL = 'UPDATE_LOGIN_MODAL'

export const UPDATE_FREE_ARTICLE_STATE = 'UPDATE_FREE_ARTICLE_STATE'

// ----------------------------------------------------------------------------
// ------------------------------------------------------------ Action creators
// ----------------------------------------------------------------------------
/** Increase */
export const increase = (initObject) => ({
  type: INCREASE,
  payload: initObject,
})

/** Decrease */
export const decrease = (initObject) => ({
  type: DECREASE,
  payload: initObject,
})

/** updateMediaState */
export const updateMediaState = (initObject) => ({
  type: UPDATE_MEDIA_STATE,
  payload: initObject,
})

/** updateScreenSizeState */
export const updateScreenSizeState = (initObject) => ({
  type: UPDATE_SCREEN_SIZE_STATE,
  payload: initObject,
})

/** updateHeaderState */
export const updateHeaderState = (initObject) => ({
  type: UPDATE_HEADER_STATE,
  payload: initObject,
})

/** updateGridOverlayViewState */
export const updateGridOverlayViewState = (initObject) => ({
  type: UPDATE_GRID_OVERLAY_VIEW_STATE,
  payload: initObject,
})

/** updateGridOverlayState */
export const updateGridOverlayState = (initObject) => ({
  type: UPDATE_GRID_OVERLAY_STATE,
  payload: initObject,
})

/** updateDayNight */
export const updateDayNightMode = (initObject) => ({
  type: UPDATE_DAY_NIGHT_MODE,
  payload: initObject,
})

/** updateColor */
export const updateColor = (initObject) => ({
  type: UPDATE_COLOR,
  payload: initObject,
})

/** updateColorRandomSwitch */
export const updateColorRandomSwitch = (initObject) => ({
  type: UPDATE_COLOR_RANDOM_SWITCH,
  payload: initObject,
})

/** updateDrawer */
export const updateDrawer = (initObject) => ({
  type: UPDATE_DRAWER,
  payload: initObject,
})

/** updateDrawerTitle */
export const updateDrawerTitle = (initObject) => ({
  type: UPDATE_DRAWER_TITLE,
  payload: initObject,
})

/** updateDrawerAbstract */
export const updateDrawerAbstract = (initObject) => ({
  type: UPDATE_DRAWER_ABSTRACT,
  payload: initObject,
})

/** updateType */
export const updateType = (initObject) => ({
  type: UPDATE_TYPE,
  payload: initObject,
})

/** toggleHeader */
export const toggleHeader = (initObject) => ({
  type: TOGGLE_HEADER,
  payload: initObject,
})

/** HEADER_MENU_STATE */
export const headerMenuState = (initObject) => ({
  type: HEADER_MENU_STATE,
  payload: initObject,
})

/** TOGGLE_HEADER_MENU */
export const toggleHeaderMenu = (initObject) => ({
  type: TOGGLE_HEADER_MENU,
  payload: initObject,
})

/** UPDATE_DRAWER_STATE */
export const updateDrawerState = (initObject) => ({
  type: UPDATE_DRAWER_STATE,
  payload: initObject,
})

/** UPDATE_CURSOR */
export const updateCursor = (initObject) => ({
  type: UPDATE_CURSOR,
  payload: initObject,
})

/** UPDATE_LOGIN_MODAL */
export const updateLoginModal = (initObject) => ({
  type: UPDATE_LOGIN_MODAL,
  payload: initObject,
})

/** UPDATE_FREE_ARTICLE_STATE */
export const updateFreeArticleState = (initObject) => ({
  type: UPDATE_FREE_ARTICLE_STATE,
  payload: initObject,
})

/** UPDATE_THEME */
export const updateTheme = (initObject) => ({
  type: UPDATE_THEME,
  payload: initObject,
})

/** UPDATE_SIZE */
export const updateSize = (initObject) => ({
  type: UPDATE_SIZE,
  payload: initObject,
})
